import request from '@/service/request'

/* 部门诊改信息 获取接口
* id
* */
export const getDeptCorrectByUserId = (id) => {
    return request.post(`/teacher/teacherTermDeptCorrect/getById/${id}`)
}
/* 部门诊改信息 删除接口
* id
* */
export const delDeptCorrectByUserId = (id) => {
    return request.post(`/teacher/teacherTermDeptCorrect/del/${id}`)
}

/* 部门诊改信息 保存接口（当前用户）
* */
export const saveDeptCorrectByCurUser = (params) => {
    return request.post('/teacher/teacherTermDeptCorrect/saveCurrentUser', params)
}
/* 部门诊改信息 保存接口
* */
export const saveDeptCorrect = (params) => {
    return request.post('/teacher/teacherTermDeptCorrect/save', params)
}

/* 部门诊改信息 page接口（当前用户）
* */
export const pageDeptCorrectByCurUser = (params) => {
    return request.post('/teacher/teacherTermDeptCorrect/pageCurrentUser', params)
}

/* 部门诊改信息 page接口
* */
export const pageDeptCorrect = (params) => {
    return request.post('/teacher/teacherTermDeptCorrect/page', params)
}
