<template>
  <el-dialog class="grade-dialog" :title="`${preTitle}诊改信息`" :visible.sync="show" :close-on-click-modal="false"
             width="1000px" :before-close="cancel">
    <el-form class="" ref="form" :model="formData" :rules="rules" label-width="150px">
      <el-row>
        <el-col :span="12">
          <el-form-item label="学年" prop="gradeName">
            <el-select :disabled="isOnlyRead" v-model="formData.gradeName" placeholder="请选择">
              <el-option v-for="item in gradeList" :key="item.id" :label="item.gradeName"
                         :value="item.gradeName"/>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="学期" prop="term">
            <el-select :disabled="isOnlyRead" v-model="formData.term" placeholder="请选择">
              <el-option v-for="item in termList" :key="item.id" :label="`第${item.paramValue}学期`"
                         :value="item.paramValue"/>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-col :span="24">
        <el-form-item label="本学期工作总结" prop="currentWorkSummary">
          <el-input maxlength="255" :disabled="isOnlyRead" v-model="formData.currentWorkSummary" type="textarea"
                    rows="3"/>
        </el-form-item>
      </el-col>
      <el-col :span="24">
        <el-form-item label="本学期大事记" prop="currentSignificantEvents">
          <el-input maxlength="255" :disabled="isOnlyRead" v-model="formData.currentSignificantEvents"
                    type="textarea" rows="3"/>
        </el-form-item>
      </el-col>
      <el-col :span="24">
        <el-form-item label="下学期工作计划" prop="nextWorkPlan">
          <el-input maxlength="255" :disabled="isOnlyRead" v-model="formData.nextWorkPlan" type="textarea"
                    rows="3"/>
        </el-form-item>
      </el-col>
      <el-col :span="24">
        <el-form-item label="自我诊断意见" prop="selfDiagnosisOpinions">
          <el-input maxlength="255" :disabled="isOnlyRead" v-model="formData.selfDiagnosisOpinions"
                    type="textarea" rows="3"/>
        </el-form-item>
      </el-col>
      <el-col :span="24">
        <el-form-item label="改进措施及成效" prop="improvement">
          <el-input maxlength="255" :disabled="isOnlyRead" v-model="formData.improvement" type="textarea"
                    rows="3"/>
        </el-form-item>
      </el-col>

    </el-form>
    <template #footer v-if="!isOnlyRead">
      <cancel-popover ref="cancelRef" :disabled.sync="loadData.loading" :update-flag="updateFlag" />
      <el-button type="primary" @click="save('诊改信息', saveDeptCorrectByCurUser)">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import dialog from '@/vue/mixins/dialog'
import userAutocomplete from '@/vue/mixins/user-autocomplete'

import { getGradeList } from '@/api/grade.js'
import { getParamsList } from '@/api/sysParams.js'

import { saveDeptCorrectByCurUser, getDeptCorrectByUserId } from '@/api/infoCollect/DeptCorrectInfo-api.js'

export default {
  name: 'gradeDialog',
  mixins: [dialog, userAutocomplete],
  data() {
    return {
      id: '',
      formData: {
        id: '',
        userId: '',
        realName: '',
        deptName: '',
        teacherNo: '',
        gradeName: '',
        term: '',
        currentWorkSummary: '',
        currentSignificantEvents: '',
        nextWorkPlan: '',
        selfDiagnosisOpinions: '',
        improvement: ''
      },
      gradeList: {},
      termList: {},
      rules: {
        gradeName: [{
          required: true,
          message: '请填写姓名'
        }],
        term: [{
          required: true,
          message: '请填写姓名'
        }]
      }
    }
  },
  props: {
    isOnlyRead: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.getGradeList()
      this.getTermList()
      if (this.id) {
        this.getDataById(this.id, getDeptCorrectByUserId)
      }
      if (this.isOnlyRead) {
        this.preTitle = '查看'
      }
    })
  },
  methods: {
    saveDeptCorrectByCurUser, // 保存 api
    // 获取学年
    getGradeList() {
      getGradeList({}).then((res) => {
        this.gradeList = res.data.list
      })
    },
    //  获取学期
    getTermList() {
      getParamsList({ keyword: 'term' }).then((res) => {
        this.termList = res.data.list
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.el-textarea,
.el-input,
.el-select {
  &.is-disabled {

    .el-textarea__inner,
    .el-input__inner {

      background-color: #fff !important;
      color: #606266 !important;
    }
  }
}
</style>
