<template>
    <table-view :total="total" :query-info.sync="queryInfo" @reload-table="renderTable">
        <template #header>
            <view-search-form :queryInfo.sync="queryInfo" @on-search="renderTable(1)" keyword-placeholder="姓名"
                :tool-list="['keyword', 'department', 'gradeName', 'term']">
            </view-search-form>
            <div class="header-button mb-1">
                <el-button @click="exportRectification()" type="success" size="small">
                    诊改信息导出
                </el-button>
            </div>
        </template>
        <el-table height="72vh" :data="tableData" class=" mt-1" style="width: 100%" border stripe>
            <el-table-column prop="deptName" label="部门"></el-table-column>
            <el-table-column prop="realName" label="姓名"></el-table-column>
            <el-table-column prop="gradeName" label="学年"></el-table-column>
            <el-table-column prop="term" label="学期">
                <template v-slot="{ row }">
                    {{ row.term ? `第${row.term}学期` : '' }}
                </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="currentWorkSummary" label="本学期工作总结"></el-table-column>
            <el-table-column show-overflow-tooltip prop="currentSignificantEvents" label="本学期大事记"></el-table-column>
            <el-table-column show-overflow-tooltip prop="nextWorkPlan" label="下学期工作计划"></el-table-column>
            <el-table-column show-overflow-tooltip prop="selfDiagnosisOpinions" label="自我诊断意见"></el-table-column>
            <el-table-column show-overflow-tooltip prop="improvement" width="150" label="改进措施及成效"></el-table-column>
            <el-table-column label="操作" width="150" fixed="right">
                <template v-slot="{ row }">
                    <el-button type="text" @click="showDialog(row.id)">
                        查看详情
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <dialog-add-rectificaition :isOnlyRead="isOnlyRead" ref='dialogRef' :visible.sync='visibleDialog'
            v-if='visibleDialog' @on-close='renderTable' />
    </table-view>
</template>

<script>
import tableView from '@/vue/mixins/table-view'

import { pageDeptCorrect } from '@/api/infoCollect/DeptCorrectInfo-api.js'
import DialogAddRectificaition from './components/DialogAddRectificaition.vue'

export default {
    components: { DialogAddRectificaition },
    mixins: [tableView],
    data() {
        return {
            isOnlyRead: true,
            queryInfo: {
                id: '',
                userId: '',
                realName: '',
                deptName: '',
                teacherNo: '',
                gradeName: '',
                term: '',
                currentWorkSummary: '',
                currentSignificantEvents: '',
                nextWorkPlan: '',
                selfDiagnosisOpinions: '',
                improvement: ''
            }
        }
    },
    mounted() {
        this.initDate()
        this.renderTable()
    },
    methods: {
        async renderTable(pageNum) {
            await this.getTableData(pageDeptCorrect, pageNum)
        },
        exportRectification() {
            this.$http.exportExcelHttp.exportRectification(this.queryInfo)
        },
        // 获取当年学期、学年
        initDate() {
            const time = new Date()
            const year = time.getFullYear()
            const month = time.getMonth() + 1

            // 当年3月-当年8月为第二学期，当年9月-次年2月为第一学期
            if (month >= 3 && month < 9) {
                this.queryInfo.term = '2'
                this.queryInfo.gradeName = `${year - 1}-${year}`
            } else if (month >= 9) {
                this.queryInfo.term = '1'
                this.queryInfo.gradeName = `${year}-${year + 1}`
            } else {
                this.queryInfo.term = '1'
                this.queryInfo.gradeName = `${year - 1}-${year}`
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.successNum {
    margin-right: 20px;
}
</style>
